<template>

  <div v-show="!hidden" class="filter-section">

    <div v-if="sectionSingle" >
      <div :class="['header-filter']">
        <div class="flex justify-between items-center">
          <span class="title-newSection"> {{ title }} </span>
        </div>
      </div>
    </div >

    <div v-else>

      <div v-if="single">
        <div :class="[(!expandir) ? 'header-filter' : 'header-filter filter-open']" >
          <div class="flex justify-between items-center">
            <span class="title-section" > {{ title }} </span>
            <div class="flex items-center">
              <b-form-checkbox v-model="singleObject.options.value" @input="emitCheck(singleObject)" />
            </div>
          </div>
        </div>
      </div>

      <div v-else>
        <div :class="[!expandir ? 'header-filter' : 'header-filter filter-open']" @click="expand()" >
         
          <div class="espaciado">
            
            <span class="title-section" > {{ title }} </span>

            <div :style="{display:'flex', 'align-items': 'center'}">
              <span v-if="cleanIconCriterio" :style="{display:'flex', 'align-items': 'center'}" >
                <i class="ki ki-round icon-sm mr-3" @click.stop="clean()"></i>
                <i :class="[!expandir ? 'ki ki-double-arrow-down icon-sm':'ki ki-double-arrow-up icon-sm']"></i>
              </span>

              <span v-else :style="{display:'flex', 'align-items': 'center'}" >
                <i v-show=" disabledSection ? false : cleanIcon  != ''" class="ki ki-round icon-sm mr-3" @click.stop="clean()"></i>
              </span>

              <i :class="[!expandir ? 'ki ki-double-arrow-down icon-sm':'ki ki-double-arrow-up icon-sm']"></i>

            </div>
          </div>
          <div v-show="!expandir" class="ingresado"> {{ seleccionados }} </div>
        </div>
        <div class="body-filter" v-show="expandir" >
          <slot />
        </div>
      </div>

    </div>

  </div>

</template>

<script>
export default {
  name: 'VxFilterSection',
  props: {
    sectionSingle: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Title'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    seleccionados: {
      type: String,
      default: ''
    },
    expandir: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    },
    cleanIcon: {
      type: String,
      default: ''
    },
    cleanIconCriterio: {
      type: Boolean,
      default: false
    },
    single: {
      type: Boolean,
      default: false
    },
    singleObject: {
      type: Object,
      default: () => {}
    },
    hidden: {
      type: Boolean,
      default: false
    },
    disabledSection: {
      type: Boolean,
      default: false,
    },
    clear: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      expandirLocal: false
    };
  },
  watch: {
    expandir(newVal) {
      this.expandirLocal = newVal;
    }
  },
  methods: {
    clean() {
      this.$emit('clean', { type: this.type, title: this.title, multiple: this.multiple, clear: this.clear });
    },
    expand() {
      this.$emit('expand', { type: this.type, title: this.title, expand: !this.expandir });
    },
    emitCheck(singleObject) {
      this.$emit('evntSingleCheck', singleObject);
    }
  }
};

</script>

<style>

.title-newSection {
  //font-size: 11px !important;
  overflow-wrap: break-word;
  //font-weight: bold;
  margin-top: 5px;
  margin-bottom: 5px;
}

.title-section {
  //font-size: 11px !important;
  overflow-wrap: break-word;
  letter-spacing: 1px;
  font-weight: 500;
}

.header-filter {
  //font-size: .9rem;
  line-height: 1.2;
  cursor: pointer;
  padding-bottom: 0.5rem;
}

.filter-open {
  min-height: 0px !important;
}

.ingresado{
  min-height: 20px;
  margin-top: 0.7rem;
  color: #2c2c2c70;
}

.body-filter {
  padding: 1.5rem .8rem 0.3rem .8rem;
}

.espaciado {
  display: flex;
  justify-content: space-between;
}

</style>
