<template>

  <trazo-off-canvas :isOpen="isOpen" id="modal-Filter-enterprise" class="Scroll-filter" :selectorToggle="selectorToggle" v-on:close="closeModal" :formulario="false" :filtro="true">

    <template v-slot:header-buttons-filter>
      <b-button class="button-filtro" variant="dark" @click="listAll">
        <i class="ki ki-round icon-nm"></i>
      </b-button>
      <b-button class="button-filtro" variant="dark" @click="openAll">
        <i :class="[!expandir ? 'ki ki-double-arrow-down icon-nm':'ki ki-double-arrow-up icon-nm']"></i>
      </b-button>
      <b-button class="button-filtro" variant="dark" @click="closeModal">
        <i class="ki ki-close icon-nm"></i>
      </b-button>
    </template>

    <b-row class="justify-content-center">
    
      <form class="full" id="formSubproject" ref="formSubproject" >

        <filter-section
          :key="index" v-for="(element, index) in filters"
          :sectionSingle="element.sectionSingle"
          :title="element.title"
          :type="element.type"
          :multiple="element.multiple"
          :seleccionados="(element.type == 'select')? (element.type == 'select' && element.multiple) ? element.options.model : element.options.model.label : (element.type == 'radio') ? element.options.model ? element.options.count.find(elemento =>{return elemento.value == element.options.model}).label : element.options.model : element.options.model"
          :expandir="element.expand"
          @expand="expand"
          @clean="clean"
          :cleanIcon="(element.type == 'select') ? (element.type == 'select' && element.multiple) ? element.options.model : element.options.model.label : element.options.model"
          :disabledSection="element.disabledSection ? element.disabledSection:false"
          :cleanIconCriterio="(element.type == 'criterio')? true : false"
          :single="element.single"
          :singleObject="(element.single) ? element : {}"
          @evntSingleCheck="evntSingleCheck"
          :hidden="element.hidden"
          :clear="element.clear ? element.clear : false" >

          <trazo-form-input
            v-if="element.type=='text'" 
            class="seccion-input" 
            id="input-text"
            v-model="element.options.model"
            :label="element.options.title"
            :disabled="element.options.disabled"
            :placeholder="element.options.placeholder"
            name="name"
            :required="false"
            labelPosition="top"
            type="text"
            autocomplete="off"
          />

          <div v-else-if="element.type=='radio'" class="seccion-input">
            <div :key="index" v-for="(radio, index) in element.options.count" >
              <b-form-radio class="mb-3" :name="element.options.model" v-model="element.options.model" :value="radio.value" @change="changeValueRadio(element,radio.value);changeRadio(element)" > 
                {{ radio.label }} 
              </b-form-radio>
            </div>
          </div>
          <trazo-form-label-slot
            v-else-if="element.type == 'select'" 
            labelPosition="left"
          >
            <a-multiselect
              :name="element.title"
              track-by="id"
              label="label"
              v-model="element.options.values"
              :multiple="true"
              :hide-selected="false"
              :placeholder="element.options.placeholder"
              :options="element.options.options"
              @input="changeSelect(element),convertModelSelectMultiple(element)"
            />
          </trazo-form-label-slot>
        </filter-section>

      </form>

    </b-row>

    <template v-slot:footer-buttons-filter>

      <b-button variant="light" @click="confirmFilter" class="btnBuscar mr-5">
        {{ "Buscar" }}
      </b-button>

    </template>

  </trazo-off-canvas>

</template>

<script>

import Vue from "vue";
import TrazoFormInput from "@/view/content/TrazoFormInput";
import TrazoOffCanvas from "@/view/content/TrazoOffCanvas";
import FilterSection from "@/view/layout/filter/FilterSection";
import helperFunctions from '@/functions';

function initialState() {
  return {
    expandir: false,
    parentx: '',
    configuration: {
      maxScrollbarLength: 90,
      whellSpeed: 0.6
    },
    filters: [],

  };
}

export default Vue.component("modal-Filter-enterprise", {

  data() {
    return initialState();
  },

  props: { 
    isOpen: {
      type: Boolean,
      default: false
    },
    selectorToggle: { 
      type: String, 
      default: "trazo_offcanvas_toggle"
    },
    filtersParent: {
      type: Array,
      default: () => []
    },
  },

  components: {
    TrazoOffCanvas,
    TrazoFormInput,
    FilterSection,
  },

  watch: {
    async isOpen(val){
      if (val) {
        await this.copyFilter(this.filtersParent);
        await this.loadDate();
        await this.loadSingleCheck();
        await this.loadMultipleCheck();
        await this.loadEvents();
      } else {
        this.filters = [];
      }
    },
    filtersParent: function(val) {
      this.filtersParent = val;
    }
  },

  methods: {

    async copyFilter(filtersParent) {
      filtersParent.forEach((filter) => {
        this.filters.push(JSON.parse(JSON.stringify(filter)));
      });
      this.filters.forEach(element =>{
        if(element.type == 'dateRange'){
          element.options.options.map((el) => {
            el.model = el.value ? el.value : '';
            el.value = el.value ? new Date(el.value) : null;
            el.disabledDate.from = el.disabledDate.from ? new Date(el.disabledDate.from) : '';
            el.disabledDate.to = el.disabledDate.to ? new Date(el.disabledDate.to) : '';
          });
        }
      })
    },

    loadSingleCheck() {
      this.filters.forEach((element) => {
        if (element.type == 'check' && !element.multiple) {
          element.options.count.forEach((check) => {
            if (check.model == true) {
              element.options.model = check.label;
              element.options.value = check.value;
              return;
            }
          });
        }
      });
    },

    loadMultipleCheck() {
      let text = '';
      let textValue = '';
      this.filtersParent.forEach((element) => {
        if (element.type == 'check' && element.multiple) {
          element.options.count.forEach((check) => {
            if (check.model) {
              text += `${check.label}, `;
              textValue += `${check.value},`;
            }
          });

          if (text) {
            element.options.model = text.slice(0, -2);
            element.options.value = textValue.slice(0, -1);
          } else {
            element.options.model = '';
            element.options.value = '';
          }
        }
      });
    },

    async loadDate() {
      this.filters.forEach((element) => {
        if (element.type == 'date') {
          if (element.options.value != null && element.options.value != '') {
            element.options.model = helperFunctions.convertDate(element.options.value);
          }
        }
        if (element.type == 'dateRange') {
          element.options.options.map((el) => {
          if (el.value != null && el.value != '') {
            el.model = helperFunctions.convertDate(el.value);
          }
        });
          
        }
      });
    },

    async loadEvents() {
      this.filters.forEach(async (element) => {
        if (element.type == 'select' && element.action) {
          await this.reactSelectHidden(element);
        }
      });
    },

    async reactSelectHidden(select) {
      const object = {
        type: select.type,
        title: select.title,
        alias: select.alias,
        valus: select.options.model
      };

      this.$emit('selectHidden', object);
    },

    evntSingleCheck(){

    },

    async clean(obj) {
      this.filters = await helperFunctions.filterClean(this.filters, obj);
      this.$emit('cleanSection',obj);
    },

    expand(obj) {
      this.filters.forEach((element) => {
        if (element.type == obj.type && element.title == obj.title) element.expand = obj.expand;
      });
    },

    closeModal() {
      this.$emit("close");
    },

    async listAll() {
      this.$emit('filterCleanAll');
      this.filters = helperFunctions.filterCleanAll(this.filters);
    },

    openAll() {
      this.expandir = !this.expandir;
      if (this.expandir)
        this.filters.forEach((element) => {
          element.expand = true;
        });
      else
        this.filters.forEach((element) => {
          element.expand = false;
        });
    },

    async changeRadio(radio) {
      const object = {
        type: radio.type,
        title: radio.title,
        alias: radio.alias,
        value: radio.options.value
      };
      this.$emit('changeRadio', object);
    },

    changeValueRadio(obj, value) {
      this.filters.forEach((element) => {
        if (element.type == obj.type && element.title == obj.title) {
          element.options.value = value;
        }
      });
    },

    confirmFilter() {
      let bool = helperFunctions.hiddenChipFilter(this.filters);
      this.$emit('confirmFilter', this.filters, bool);
    },
    
    convertModelSelectMultiple(obj) {
      this.filters.forEach((element) => {
        if (element.type == obj.type && element.multiple && element.title == obj.title) {
          let text = '';
          element.options.values.forEach((values) => {
            text += `${values.label}, `;
          });
          element.options.model = text.slice(0, -2);
        }
      });
    },

    async changeSelect(select) {
      let object = {};

      if (select.multiple) {
        if (select.options.values.length > this.longSelectMultiple) select.options.values.pop();
        object = {
          type: select.type,
          title: select.title,
          alias: select.alias,
          value: select.options.values
        };
      } else {
        object = {
          type: select.type,
          title: select.title,
          alias: select.alias,
          value: select.options.model
        };
      }
      this.$emit('changeSelect', object);
    },

  },

});

</script>

<style lang="scss">

.scroll-filter{
  overflow: scroll;
}

.btnBuscar{
  float: right;
  background: #151515f0 !important;
  color: #FFFFFF !important;
}

.filter-section{
  margin-bottom: 1.8rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.form-control .form-group .seccion-input {
  margin-bottom: .8rem !important;
}

.button-filtro{
  margin-top: .4rem !important;
  color: #ffffff !important; 
  fill: currentColor;
  &:hover {
    background: transparent !important;
  }
  &:active {
    background: transparent !important;
  }
}

</style>