<template>
  <div v-if="!hidden" :style="`background-color:${colorBackground}`" class="chipCss" >
    <VuePerfectScrollbar class="scroll-area" :settings="configuration" >
      <div>
        <div class="chips_min_width">
          <span class="chips_align_center" > {{ title }} </span>
          <div class="chips" v-show="element.model != ''" :color="color" @click="remove(element)" v-for="(element, index) in filters" :key="index" :closable="element.closable" >
            <span> {{ element.title }} : </span>
            <span> {{ element.model }}</span>
          </div>
        </div>
      </div>
    </VuePerfectScrollbar>
  </div>
</template>

<script>

import helperFunctions from '@/functions';

export default {

  name: 'FilterChip',
  
  components: {
    VuePerfectScrollbar: () => import('vue-perfect-scrollbar')
  },

  props: {
    filtersParent: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: 'Title'
    },
    colorBackground: {
      type: String,
      default: 'primary'
    },
    color: {
      type: String,
      default: 'primary'
    },
    hidden: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    count: 0,
    configuration: {
      maxScrollbarLength: 90,
      whellSpeed: 0.6
    }
  }),

  watch: {
    
  },

  computed: {
    filters() {
      return helperFunctions.filterMapChips(this.filtersParent);
    }
  },

  methods: {

    remove(obj) {
      let tmpFilterParent = helperFunctions.filterClean(this.filtersParent, obj);
      this.$emit('remove', tmpFilterParent);
    },
    
    updateFilters: (filters, index) => filters.splice(index, 1),

    loadSingleCheck() {
      this.filtersParent.forEach((element) => {
        if (element.type == 'check' && !element.multiple) {
          element.options.count.forEach((check) => {
            if (check.model == true) {
              element.options.model = check.label;
              element.options.value = check.value;
              return;
            }
          });
        }
      });
    },

    loadMultipleCheck() {
      let text = '';
      let textValue = '';
      this.filtersParent.forEach((element) => {
        if (element.type == 'check' && element.multiple) {
          element.options.count.forEach((check) => {
            if (check.model) {
              text += `${check.label}, `;
              textValue += `${check.value},`;
            }
          });

          if (text) {
            element.options.model = text.slice(0, -2);
            element.options.value = textValue.slice(0, -1);
          } else {
            element.options.model = '';
            element.options.value = '';
          }
        }
      });
    },

    async loadDate() {
      this.filtersParent.forEach((element) => {
        if (element.type == 'date') {
          element.options.model = helperFunctions.convertDate(element.options.value);
        }
      });
    }

  },

  async mounted() {

    await this.loadDate();
    await this.loadSingleCheck();
    await this.loadMultipleCheck();
    this.count = helperFunctions.countFiltersValues(this.filtersParent);
    
  }

};

</script>

<style>

.chips_align_center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  margin-left: 10px;
}
.chipCss{
  border-radius: 10px;
  padding: .4rem;
}
.chips_min_width {
  display: flex;
  min-width: 1920px;
  /*min-width: 100%*/
}

.chips{
  background-color: #DFDEFA;
  margin-right: 6px;
  border-radius: 10px;
  padding: 0.3rem .5rem .3rem .5rem;
}

</style>