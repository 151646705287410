
export default {

  // Funciones reutilizables en el chip y el sidebar del filtro

  async filterClean(filters, obj) {
    let childsActions = [];

    filters.forEach((element) => {
      if (obj.type == 'select' && obj.title == element.title) {
        if (element.action) childsActions = element.change ? element.change.slice() : [];
      }
      if (obj.type == 'text' && obj.title == element.title) {
        if (element.action) childsActions = element.change ? element.change.slice() : [];
      }
      if (obj.type == 'check' && obj.title == element.title) {
        if (element.action) childsActions = element.change ? element.change.slice() : [];
      }
      if (obj.type == 'criterio' && obj.title == element.title) {
        if (element.action) childsActions = element.change ? element.change.slice() : [];
      }

      if (obj.type == 'radio' && obj.title == element.title) {
        if (element.action) childsActions = element.change ? element.change.slice() : [];
      }

      if (obj.type == 'singleCheck' && obj.title == element.title) {
        if (element.action) childsActions = element.change ? element.change.slice() : [];
      }
    });

    filters.forEach((element) => {
      if (obj.type == 'select' && obj.title == element.title) {
        element.options.model = '';
      }
      if (obj.type == 'select' && obj.multiple && obj.title == element.title) {
        element.options.model = '';
        element.options.values = [];
      }
      if (obj.type == 'popUp' && obj.title == element.title) {
        element.options.model = '';
        element.options.options.map((el) => {
          el.model = '';
          if(el.tipo=='texto') el.values = '';
        });
      }
      if (obj.type == 'treePopUp' && obj.title == element.title) {
        if(obj.clear == true) return
        element.options.model = '';
        element.options.value = null;
        element.options.aux = [];
        element.options.optionsPopUp.map((el) => {
          el.model = '';
          if(el.tipo=='texto') el.values = '';
        });
      }
      if (obj.type == 'selectGroup' && obj.title == element.title) {
        element.options.model = '';
        element.options.options.map((el) => {
          el.model = '';
          el.values = el.multiple ? [] : '';
        });
      }
      if (obj.type == 'tree' && obj.title == element.title) {
        if(obj.clear == true) return
        element.options.model = '';
        element.options.value = null;
        element.options.aux = [];
      }
      console.info('paso')
      if (obj.type == 'date' && obj.title == element.title) {
        element.options.model = '';
        element.options.value = null;
      }
      if (obj.type == 'dateRange' && obj.title == element.title) {
        element.options.model = '';
        element.options.options.map((el) => {
          el.model = '';
          el.value = null;
          if(el.disabledDate){ 
            el.disabledDate.to = '';
            el.disabledDate.from = '';
          }
          if(el.valueHora) el.valueHora = '';
        });
      }
      if (obj.type == 'number' && obj.title == element.title) {
        element.options.model = '';
      }
      if (obj.type == 'text' && obj.title == element.title) {
        element.options.model = '';
      }
      if (obj.type == 'check' && obj.title == element.title) {
        element.options.model = '';
        element.options.count.forEach((check) => {
          check.model = false;
        });
      }
      if (obj.type == 'criterio' && obj.title == element.title) {
        element.options.input = '';
        element.options.select = '';
        element.options.model = '';

        element.options.criterio_multiple.forEach((cmultiple) => {
          cmultiple.visible = cmultiple.index == 0 ? true : false;
          cmultiple.add = cmultiple.index == 0 ? true : false;
          cmultiple.modelChild = '';
          cmultiple.selectChild = '';
          cmultiple.inputChild = '';
          cmultiple.inputChildInicio = '';
          cmultiple.inputChildFin = '';
          cmultiple.radio = cmultiple.radio_and;
        });
      }
      if (obj.type == 'radio' && obj.title == element.title) {
        element.options.model = '';
        element.options.value = '';
      }
      if (obj.type == 'singleCheck' && obj.title == element.title) {
        element.options.model = '';
        element.options.value = false;
      }
    });

    if (childsActions.length > 0) {
      childsActions.forEach((child) => {
        filters.forEach((element) => {
          if (child.type == 'select' && child.child == element.title) {
            element.options.model = '';
            element.options.options = [];
          }
          if (child.type == 'select' && child.multiple && child.child == element.title) {
            element.options.model = '';
            element.options.values = [];
          }
          if (child.type == 'date' && child.child == element.title) {
            element.options.model = '';
            element.options.value = null;
          }
          if (child.type == 'dateRange' && child.child == element.title) {
            element.options.model = '';
            element.options.options.map((el) => {
              el.model = '';
              el.value = null;
              if(el.disabledDate){ 
                el.disabledDate.to = '';
                el.disabledDate.from = '';
              }
              if(el.valueHora) el.valueHora = '';
            });
          }
          if (child.type == 'number' && child.child == element.title) {
            element.options.model = '';
          }
          if (child.type == 'text' && child.child == element.title) {
            element.options.model = '';
          }
          if (child.type == 'check' && child.child == element.title) {
            element.options.model = '';
            element.options.count.forEach((check) => {
              check.model = false;
            });
          }
          if (child.type == 'criterio' && child.child == element.title) {
            element.options.input = '';
            element.options.select = '';
            element.options.model = '';
          }
          if (child.type == 'radio' && child.child == element.title) {
            element.options.model = '';
            element.options.value = '';
          }
          if (child.type == 'singleCheck' && child.child == element.title) {
            element.options.model = '';
            element.options.value = false;
          }
        });
      });
    }

    return filters;
  },

  filterCleanAll(filters) {
    filters.forEach((element) => {
      if (element.type == 'select' && !element.disabledSection) {
        element.options.model = '';
      }
      if (element.type == 'select' && element.multiple && !element.disabledSection) {
        element.options.model = '';
        element.options.values = [];
      }
      if (element.type == 'popUp') {
        element.options.model = '';
        element.options.options.map((el) => {
          el.model = '';
          if(el.tipo=='texto') el.values = '';
        });
      }
      if (element.type == 'treePopUp') {
        element.options.model = '';
        element.model = '';
        if(element.clear == true) return
        element.options.model = '';
        element.options.value = null;
        element.options.aux = [];
        element.options.optionsPopUp.map((el) => {
          el.model = '';
          if(el.tipo=='texto') el.values = '';
        });
      }
      if (element.type == 'selectGroup') {
        element.options.model = '';
        element.options.options.map((el) => {
          el.model = '';
          el.values = el.multiple ? [] : '';
        });
      }
      if (element.type == 'date') {
        element.options.model = '';
        element.options.value = null;
      }
      if (element.type == 'dateRange') {
        element.options.model = '';
        element.options.options.map((el) => {
          el.model = '';
          el.value = null;
          if(el.disabledDate){ 
            el.disabledDate.to = '';
            el.disabledDate.from = '';
          }
          if(el.valueHora) el.valueHora = '';
        });
      }
      if (element.type == 'tree') {
        if(element.clear == true) return
        element.options.model = '';
        element.options.value = null;
        element.options.aux = [];
      }
      if (element.type == 'number' && !element.disabledSection) {
        element.options.model = '';
      }
      if (element.type == 'text' && !element.disabledSection ) {
        element.options.model = '';
      }
      if (element.type == 'check') {
        element.options.model = '';
        element.options.value = '';
        element.options.count.forEach((check) => {
          check.model = false;
        });
      }
      if (element.type == 'criterio') {
        element.options.input = '';
        element.options.select = '';
        element.options.model = '';

        element.options.criterio_multiple.forEach((cmultiple) => {
          cmultiple.visible = cmultiple.index == 0 ? true : false;
          cmultiple.add = cmultiple.index == 0 ? true : false;
          cmultiple.modelChild = '';
          cmultiple.selectChild = '';
          cmultiple.inputChild = '';
          cmultiple.inputChildInicio = '';
          cmultiple.inputChildFin = '';
          cmultiple.radio = cmultiple.radio_and;
        });
      }
      if (element.type == 'radio') {
        element.options.model = '';
        element.options.value = '';
      }
      if (element.type == 'singleCheck') {
        element.options.model = '';
        element.options.value = false;
      }
    });

    return filters;
  },

  filterMapChips(filters) {
    let tmpFilters = [];
    filters.forEach((element) => {
      if (element.type == 'select') {
        if (element.multiple) {
          tmpFilters.push({
            title: element.title,
            model: element.options.model ? element.options.model : '',
            closable: element.closable
          });
        } else {
          tmpFilters.push({
            title: element.title,
            model: element.options.model ? element.options.model.label : '',
            closable: element.closable
          });
        }
      }
      if (element.type == 'selectGroup') {
        element.options.options.forEach((el) => {
          tmpFilters.push({
            title: el.abbr,
            model: el.model.split(':').length > 1 ? el.model.split(':')[1].trim() : '',
            closable: false
          });
        });
      }
      if (element.type == 'popUp') {
        element.options.options.forEach((el) => {
          tmpFilters.push({
            title: el.abbr,
            model: el.model,
            closable: false
          });
        });
      }
      if (element.type == 'treePopUp') {
        element.options.optionsPopUp.forEach((el) => {
          tmpFilters.push({
            title: el.abbr,
            model: el.model,
            closable: false
          });
        });
      }
      if (element.type == 'date') {
        tmpFilters.push({
          title: element.title,
          model: element.options.model,
          closable: element.closable
        });
      }
      if (element.type == 'dateRange') {
        tmpFilters.push({
          title: element.title,
          model: element.options.model,
          closable: element.closable
        });
      }
      if (element.type == 'tree') {
        tmpFilters.push({
          title: element.title,
          model: element.options.model,
          closable: element.closable
        });
      }
      if (element.type == 'number') {
        tmpFilters.push({
          title: element.title,
          model: element.options.model,
          closable: element.closable
        });
      }
      if (element.type == 'text') {
        tmpFilters.push({
          title: element.title,
          model: element.options.model,
          closable: element.closable
        });
      }
      if (element.type == 'check') {
        tmpFilters.push({
          title: element.title,
          model: element.options.model,
          closable: element.closable
        });
      }
      if (element.type == 'criterio') {
        if (element.options.model != null && element.options.model != '') {
          let tmpCriterio = element.options.model ? element.options.model.split(':') : '';
          tmpFilters.push({
            title: tmpCriterio[0].trim(),
            model: tmpCriterio[1].trim(),
            closable: element.closable
          });
        }
      }
      if (element.type == 'radio') {
        tmpFilters.push({
          title: element.title,
          model: element.options.model ? element.options.count.find(elemento =>{return elemento.value == element.options.model}).label : '',
          closable: element.closable
        });
      }
      if (element.type == 'singleCheck') {
        tmpFilters.push({
          title: element.title,
          model: element.options.model,
          closable: element.closable
        });
      }
    });

    return tmpFilters;
  },

  hiddenChipFilter(filters) {
    let temporal = '';

    filters.forEach((element) => {
      if (element.type == 'select') {
        temporal += element.options.model;
      }
      if (element.type == 'select' && element.multiple) {
        temporal += element.options.model;
      }
      if (element.type == 'date') {
        temporal += element.options.model;
      }
      if (element.type == 'dateRange') {
        temporal += element.options.model;
      }
      if (element.type == 'tree') {
        temporal += element.options.model;
      }
      if (element.type == 'number') {
        temporal += element.options.model;
      }
      if (element.type == 'text') {
        temporal += element.options.model;
      }
      if (element.type == 'check') {
        temporal += element.options.model;
      }
      if (element.type == 'criterio') {
        temporal += element.options.model;
      }
      if (element.type == 'radio') {
        temporal += element.options.model;
      }
      if (element.type == 'singleCheck') {
        temporal += element.options.model;
      }
      if (element.type == 'popUp') {
        element.options.options.forEach((el) => {
          temporal += el.model;
        });
      }
      if (element.type == 'treePopUp') {
        element.options.optionsPopUp.forEach((el) => {
          temporal += el.model;
        });
      }
      if (element.type == 'selectGroup') {
        element.options.options.forEach((el) => {
          temporal += el.model;
        });
      }
    });

    return temporal == '';
  },

  mapFilters(filters) {
    let body = {};

    filters.forEach((element) => {
      if (element.type == 'select') {
        body[element.alias] = element.options.model.id ? element.options.model.id : '';
      }
      if (element.type == 'select' && element.multiple) {
        let arrayTmpId = [];
        let arrayTmpDescripcion = [];

        if (element.options.values.length < 1) {
          body[element.alias] = {
            id: '',
            descripcion: ''
          };
          return;
        }
        element.options.values.forEach((select) => {
          arrayTmpId.push(select.id);
          arrayTmpDescripcion.push(select.label);
        });

        body[element.alias] = {
          id: arrayTmpId.length > 0 ? arrayTmpId.join(',') : '',
          descripcion: arrayTmpDescripcion.length > 0 ? arrayTmpDescripcion.join(',') : ''
        };
      }
      if (element.type == 'popUp') {
        element.options.options.forEach((e) => {
          body[e.alias] = e.tipo=='texto' ? e.values ? e.values : '' : e.model ? e.model : '';
        });
      }
      if (element.type == 'treePopUp') {
        element.options.optionsPopUp.forEach((e) => {
          body[e.alias] = e.tipo=='texto' ? e.values ? e.values : '' : e.model ? e.model : '';
        });
      }
      if (element.type == 'selectGroup') {
        element.options.options.forEach((e) => {
          if (e.multiple) {
            let arrayTmpId = [];
            let arrayTmpDescripcion = [];

            if (e.values.length < 1) {
              body[e.alias] = {
                id: '',
                descripcion: ''
              };
              return;
            }
            e.values.forEach((select) => {
              arrayTmpId.push(select.id);
              arrayTmpDescripcion.push(select.label);
            });

            body[element.alias] = {
              id: arrayTmpId.length > 0 ? arrayTmpId.join(',') : '',
              descripcion: arrayTmpDescripcion.length > 0 ? arrayTmpDescripcion.join(',') : ''
            };
          } else {
            body[e.alias] = e.values != '' && e.values != null ? e.values.id : '';
          }
        });
      }
      if (element.type == 'text') {
        body[element.alias] = element.options.model ? element.options.model : '';
      }
      if (element.type == 'tree') {
        body[element.alias] = element.options.value ? element.options.value : '';
      }
      if (element.type == 'number') {
        body[element.alias] = element.options.model ? element.options.model : '';
      }
      if (element.type == 'date') {
        body[element.alias] = element.options.model ? element.options.model : '';
      }
      if (element.type == 'dateRange') {
        if( element.hora ){
          body[element.alias] = {
            desde: element.options.options[0].model ? element.options.options[0].model + (element.options.options[0].valueHora ?  ' '+element.options.options[0].valueHora : ' 00:00:00') : '',
            hasta: element.options.options[1].model ? element.options.options[1].model + (element.options.options[1].valueHora ?  ' '+element.options.options[1].valueHora : ' 23:59:59') : '',
          };
        }else{
          body[element.alias] = {
            desde: element.options.options[0].model ? element.options.options[0].model : '',
            hasta: element.options.options[1].model ? element.options.options[1].model : '',
          };
        }
      }
      if (element.type == 'check') {
        body[element.alias] = element.options.value ? element.options.value : '';
      }
      if (element.type == 'radio') {
        body[element.alias] = element.options.value ? element.options.value : '';
      }
      if (element.type == 'singleCheck') {
        body[element.alias] = element.options.model ? element.options.model : '';
      }
      if (element.type == 'criterio') {
        element.options.criterio_multiple.forEach((cmultiple) => {
          if (cmultiple.index == 0) {
            body[`${element.alias}${cmultiple.index}`] = {};

            cmultiple.options.forEach((option) => {
              if(option.type=='numberRange'){
                if (cmultiple.selectChild.id == option.id) body[`${element.alias}${cmultiple.index}`][`${option.id}`] = `${cmultiple.inputChildInicio}*${cmultiple.inputChildFin}`;
                else body[`${element.alias}${cmultiple.index}`][`${option.id}`] = '';
              }else{
                if (cmultiple.selectChild.id == option.id) body[`${element.alias}${cmultiple.index}`][`${option.id}`] = cmultiple.inputChild;
                else body[`${element.alias}${cmultiple.index}`][`${option.id}`] = '';
              }
            });
          } else if (cmultiple.index == element.options.size_criterio_multiple - 1) {
            body[`${element.alias}${cmultiple.index}`] = {};

            cmultiple.options.forEach((option) => { 
              if(option.type=='numberRange'){
                if (cmultiple.selectChild.id == option.id) body[`${element.alias}${cmultiple.index}`][`${option.id}`] = `${cmultiple.inputChildInicio}*${cmultiple.inputChildFin}`;
                else body[`${element.alias}${cmultiple.index}`][`${option.id}`] = '';
              }else{
                if (cmultiple.selectChild.id == option.id) body[`${element.alias}${cmultiple.index}`][`${option.id}`] = cmultiple.inputChild;
                else body[`${element.alias}${cmultiple.index}`][`${option.id}`] = '';
              }
            });
          } else {
            body[`${element.alias}${cmultiple.index}`] = {};

            cmultiple.options.forEach((option) => {
              if(option.type=='numberRange'){
                if (cmultiple.selectChild.id == option.id) body[`${element.alias}${cmultiple.index}`][`${option.id}`] = `${cmultiple.inputChildInicio}*${cmultiple.inputChildFin}`;
                else body[`${element.alias}${cmultiple.index}`][`${option.id}`] = '';
              }else{
                if (cmultiple.selectChild.id == option.id) body[`${element.alias}${cmultiple.index}`][`${option.id}`] = cmultiple.inputChild;
                else body[`${element.alias}${cmultiple.index}`][`${option.id}`] = '';
              }
            });
          }
        });
      }
    });

    return body;
  },

  loaderFilters(filters) {
    filters.forEach((element) => {
      if (element.type == 'criterio') {
        // DESDE AQUI ESTA EL CRITERIO MULTIPLE PERO SE E PENSADO PASAR ESO A UNA FUNCION INDEPENDIENTE
        for (let index = 0; index < element.options.size_criterio_multiple; index++) {
          element.options.criterio_multiple.push({
            index: index,

            options: [...element.options.options],

            showInput: false, // esto es el input de cada criterio
            visible: index == 0 ? true : false, // esto es toda la seccion de criterio
            add: index == 0 ? true : false, // esto es lo del boton

            modelChild: '',
            selectChild: '',
            inputChild: '',
            inputChildInicio: '',
            inputChildFin: '',
            radio: `and-${index}`,
            radio_and: `and-${index}`,
            radio_or: `or-${index}`,
            
            placeholder: element.options.placeholder,
          });
        }
      }
    });

    return filters;
  },

  countFiltersValues(filters) {
    let count = 0;

    filters.forEach((element) => {
      if (element.type == 'select') {
        if (element.options.model != '') count++;
      }
      if (element.type == 'text') {
        if (element.options.model != '') count++;
      }
      if (element.type == 'check') {
        if (element.options.model != '') count++;
      }
      if (element.type == 'criterio') {
        if (element.options.input != '' && element.options.select != '' && element.options.model != '') count++;
      }
    });
    return count;
  },

  // PARA LA COMPARACION DEL LOS VALORES DEL POPUP SE REALIZA UN FILTER
  filterValuesPopUp(values, valuePopup, property) {
    let arrTmp = [];

    let arrValues = valuePopup.split(',').filter((e) => e != '');

    arrValues.forEach((e) => {
      let val = values.filter((val) => val[property] === e);

      if (val.length > 0) arrTmp.push(val[0]);
    });

    return arrTmp;
  },

  insertDataSelectFilter(filter, alias, options, model = '') {
    filter.map((e) => {
      if (e.alias === alias) {
        if (e.multiple) {
          e.options.model = model == '' ? '' : [model].map((e) => e.label).join(',');
          e.options.values = model == '' ? [] : [model];
          e.options.options = options;
        } else {
          e.options.values = model;
          e.options.options = options;
        }
        return e;
      }
    });
    return filter;
  },

  convertDate(date) {
    let tmpDate = new Date(date)
      .toLocaleString()
      .split(' ')[0]
      .split('/');
    for (let index = 0; index < tmpDate.length; index++) {
      tmpDate[index] = tmpDate[index].padStart(2, '0');
    }
    return tmpDate.join('/');
  },

};